import "./projects.css"
import React, {useEffect, useState} from "react";
import { Blurhash } from "react-blurhash";
import {Helmet} from "react-helmet";

let im1 = "https://res.cloudinary.com/mariah07/image/upload/v1698091330/consult_pinto/texas_ebd6dq_i7roch.jpg";
let im2 = "https://res.cloudinary.com/mariah07/image/upload/v1698091331/consult_pinto/Thomos_duct_sfmv4j_eblkyx.jpg";

const Industrial = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [im1Loaded, setLoadedIM1] = useState(false);
    const [im2Loaded, setLoadedIM2] = useState(false);

    const setLoaded = [setLoadedIM1, setLoadedIM2];
    let i = 0;

    useEffect(() => {
        const imagesPreload = [im1, im2];
        imagesPreload.forEach((image) => {
            const newImage = new Image();
            newImage.onload = () => {
                setLoaded[i](true);
                i = i + 1;
            }
            newImage.src = image;
            window[image] = newImage;
        });
    }, []);
    return (
        <section id="project">
            <Helmet>
                <title>Industrial | Pinto Engineering</title>
                <meta name="description" content="PINTO has contributed to nearly 2,500 projects since being established in 1987 including commercial, industrial, residential high-rises, bridges, and more."/>
                <meta name="robots" content="index,follow"/>
            </Helmet>
            <div className="button-area">
                <a className="button" href="/projects"><i class="far fa-arrow-alt-circle-left"></i></a>
                <h1>Industrial</h1>
            </div>
            <div className="overlay"></div>
            <div className="row">
                <div className="box">
                    <a href="/projects/industrial-aviation/texas-duct">
                    {!im1Loaded && ( <Blurhash 
                         hash="LHF5]Z4T?wx80LVrg5sQ~p%1jFoz"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im1Loaded && <img loading="eager" src={im1} 
                    alt="Texas Duct - PINTO Engineering"/> }
                    <h3>Texas Duct, Texas, United States</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/industrial-aviation/thomas-hill">
                    {!im2Loaded && ( <Blurhash 
                         hash="LeL34{D%.jx[8y%LMyMz%eVtjJtQ"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im2Loaded && <img loading="eager" src={im2} 
                    alt="Thomas Hill Environmental Retro Fit - PINTO Engineering"/> }
                    <h3>Thomas Hill Environmental Retro Fit, Burns & McDonnell, Kansas City, Missouri, USA</h3></a>
                </div>
            </div>
        </section>
    )
}

export default Industrial;