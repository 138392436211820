import "./projects.css"
import React, {useEffect, useState} from "react";
import { Blurhash } from "react-blurhash";
import {Helmet} from "react-helmet";

let im1 = "https://res.cloudinary.com/mariah07/image/upload/v1698091312/consult_pinto/inverary_manor_s98fl6_drqjnb.jpg";
let im2 = "https://res.cloudinary.com/mariah07/image/upload/v1698091337/consult_pinto/wickwire_aqkdha_fmvmuw.jpg";
let im3 = "https://res.cloudinary.com/mariah07/image/upload/v1698091305/consult_pinto/foyer_ivdcsk_phnj6v.jpg";
let im4 = "https://res.cloudinary.com/mariah07/image/upload/v1698091338/consult_pinto/windsor_daggtl_dy7xdj.jpg";
let im5 = "https://res.cloudinary.com/mariah07/image/upload/v1698091323/consult_pinto/northwood_oge6kf_o5rjmu.jpg";

const Health = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [im1Loaded, setLoadedIM1] = useState(false);
    const [im2Loaded, setLoadedIM2] = useState(false);
    const [im3Loaded, setLoadedIM3] = useState(false);
    const [im4Loaded, setLoadedIM4] = useState(false);
    const [im5Loaded, setLoadedIM5] = useState(false);

    const setLoaded = [setLoadedIM1, setLoadedIM2, setLoadedIM3, setLoadedIM4, setLoadedIM5];
    let i = 0;

    useEffect(() => {
        const imagesPreload = [im1, im2, im3, im4, im5];
        imagesPreload.forEach((image) => {
            const newImage = new Image();
            newImage.onload = () => {
                setLoaded[i](true);
                i = i + 1;
            }
            newImage.src = image;
            window[image] = newImage;
        });
    }, []);
    return (
        <section id="project">
            <Helmet>
                <title>Health | Pinto Engineering</title>
                <meta name="description" content="PINTO has contributed to nearly 2,500 projects since being established in 1987 including commercial, industrial, residential high-rises, bridges, and more."/>
                <meta name="robots" content="index,follow"/>
            </Helmet>
            <div className="button-area">
                <a className="button" href="/projects"><i class="far fa-arrow-alt-circle-left"></i></a>
                <h1>Health</h1>
            </div>
            <div className="overlay"></div>
            <div className="row">
                <div className="box">
                    <a href="/projects/health/inverary-manor-nursing-home">
                        {!im1Loaded && ( <Blurhash 
                         hash="LZFY+AtRRkof.Tt7ayfjo#aej?ay"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im1Loaded && <img loading="eager" src={im1} 
                    alt="Inverary Manor Nursing Home - PINTO Engineering"/> }
                    <h3>Inverary Manor Nursing Home, Inverness, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/health/wickwire-assisted-living-residence">
                    {!im2Loaded && ( <Blurhash 
                         hash="LdDv.$D$V=slyGelRPRjXAs.WAbI"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im2Loaded && <img loading="eager" src={im2} 
                    alt="Wickwire Place Assisted Living Residence - PINTO Engineering"/> }
                    <h3>Wickwire Place Assisted Living Residence, Wolfville, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/health/foyer-longterm-care-facility">
                    {!im3Loaded && ( <Blurhash 
                         hash="LwLgw+WBxuae~qoet7ay?bayaej["
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im3Loaded && <img loading="eager" src={im3} 
                    alt="Foyer Pere Fiset Long Term Care Facility - PINTO Engineering"/> }
                    <h3>Foyer Pere Fiset Long Term Care Facility, Cheticamp, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/health/windsor-elms-village">
                    {!im4Loaded && ( <Blurhash 
                         hash="LtH.jHRjRjWV.AofWBa#M{t7ofWC"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im4Loaded && <img loading="eager" src={im4} 
                    alt="Windsor Elms Village - PINTO Engineering"/> }
                    <h3>Windsor Elms Village, Falmouth, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/health/northwood-continuing-care">
                    {!im5Loaded && ( <Blurhash 
                         hash="LGD+#$-OI=WB1BoIs-WY01I[wbj]"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im5Loaded && <img loading="eager" src={im5} 
                    alt="Northwood Continuing Care - PINTO Engineering"/> }
                    <h3>Northwood Continuing Care Centre - Ivany Place, Bedford, NS</h3></a>
                </div>
            </div>
        </section>
    )
}

export default Health;