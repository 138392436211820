import "./projects.css"
import React, {useEffect, useState} from "react";
import { Blurhash } from "react-blurhash";
import {Helmet} from "react-helmet";

let im1 = "https://res.cloudinary.com/mariah07/image/upload/v1698091332/consult_pinto/trillium_jking7_ccu4s6.jpg";
let im2 = "https://res.cloudinary.com/mariah07/image/upload/v1698091328/consult_pinto/St._Lawrence_bcxwfh_gnryzk.jpg";
let im3 = "https://res.cloudinary.com/mariah07/image/upload/v1698091318/consult_pinto/mary-ann_orwayo_sevfsl.jpg";
let im4 = "https://res.cloudinary.com/mariah07/image/upload/v1698091303/consult_pinto/duckworth_vwqrme_zcvrpv.jpg";
let im5 = "https://res.cloudinary.com/mariah07/image/upload/v1698091294/consult_pinto/brigadoon_yewraw_g7cj7b.jpg";
let im6 = "https://res.cloudinary.com/mariah07/image/upload/v1698091317/consult_pinto/marriott_xdmz0l_uldhfr.jpg";

const Mixed = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [im1Loaded, setLoadedIM1] = useState(false);
    const [im2Loaded, setLoadedIM2] = useState(false);
    const [im3Loaded, setLoadedIM3] = useState(false);
    const [im4Loaded, setLoadedIM4] = useState(false);
    const [im5Loaded, setLoadedIM5] = useState(false);
    const [im6Loaded, setLoadedIM6] = useState(false);

    const setLoaded = [setLoadedIM1, setLoadedIM2, setLoadedIM3, setLoadedIM4, setLoadedIM5, setLoadedIM6];
    let i = 0;

    useEffect(() => {
        const imagesPreload = [im1, im2, im3, im4, im5, im6];
        imagesPreload.forEach((image) => {
            const newImage = new Image();
            newImage.onload = () => {
                setLoaded[i](true);
                i = i + 1;
            }
            newImage.src = image;
            window[image] = newImage;
        });
    }, []);
    return (
        <section id="project">
            <Helmet>
                <title>Mixed-Use Development | Pinto Engineering</title>
                <meta name="description" content="PINTO has contributed to nearly 2,500 projects since being established in 1987 including commercial, industrial, residential high-rises, bridges, and more."/>
                <meta name="robots" content="index,follow"/>
            </Helmet>
            <div className="button-area">
                <a className="button" href="/projects"><i class="far fa-arrow-alt-circle-left"></i></a>
                <h1>Mixed-Use</h1>
            </div>
            <div className="overlay"></div>
            <div className="row">
                <div className="box">
                    <a href="/projects/mixed-use-development/the-trillium">
                    {!im1Loaded && ( <Blurhash 
                         hash="LXF6qqp0KBg4N#RjXAn#ROaen}s+"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im1Loaded && <img loading="eager" src={im1} 
                    alt="The Trillium - PINTO Engineering"/> }
                    <h3>The Trillium, Halifax, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/mixed-use-development/saint-lawerence">
                    {!im2Loaded && ( <Blurhash 
                         hash="LLEWON9Gtns.yGM{Ipoz^*IoMwWE"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im2Loaded && <img loading="eager" src={im2} 
                    alt="Saint Lawrence Place - PINTO Engineering"/> }
                    <h3>Saint Lawrence Place, Halifax, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/mixed-use-development/mary-ann">
                    {!im3Loaded && ( <Blurhash 
                         hash="LVEMtds:.AV@cHogp0oft8t8aJj]"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im3Loaded && <img loading="eager" src={im3} 
                    alt="Mary Ann Development - PINTO Engineering"/> }
                    <h3>Mary Ann Development, Halifax, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/mixed-use-development/duckworth-street-metropark">
                    {!im4Loaded && ( <Blurhash 
                         hash="LaH.KHtR~qRPx^xu%NRj%Mt7WWRj"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im4Loaded && <img loading="eager" src={im4} 
                    alt="Duckworth Street Metropark - PINTO Engineering"/> }
                    <h3>Duckworth Street Metropark, St John's, Newfoundland and Labrador</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/mixed-use-development/brigadoon-village">
                    {!im5Loaded && ( <Blurhash 
                         hash="L+I5MWxtxuWB~qt7xuWB-;ofRjWB"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im5Loaded && <img loading="eager" src={im5} 
                    alt="Brigadoon Village - PINTO Engineering"/> }
                    <h3>Brigadoon Village, South Alton, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/mixed-use-development/marriott-residence-inn">
                    {!im6Loaded && ( <Blurhash 
                         hash="LgI#AQIU.9ae~qR*-;of%gt7xuj]"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im6Loaded && <img loading="eager" src={im6} 
                    alt="Marriott Residence Inn - PINTO Engineering"/> }
                    <h3>Marriott Residence Inn, Halifax, NS</h3></a>
                </div>
            </div>
        </section>
    )
}

export default Mixed;