import React, {useState, useEffect, useRef} from 'react';
import { NavLink } from 'react-router-dom'
import logo from '../img/logo.png'; 
import { useHistory  } from 'react-router-dom';

const Navbar = () => {
    const wrapperRef = useRef(null);
    const [mobile,setMobile] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [icon, setIcon] = useState(false);
    const history = useHistory();

    const setOverlay = (mobile) => {
      const element = document.getElementsByClassName("overlay")[0];
      if(mobile) {
          element.style.display = "block";
          element.style.opacity = "1";
      }
      else {
          element.style.display = "";
          element.style.opacity = "";
      }
  }

    const Scroll = () => {
      setScroll(window.scrollY > 50);
      setIcon(window.scrollY > 50);
    }

    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setMobile(false);
        setOverlay(false);
      }
    };

    let prevScrollPos = window.pageYOffset;

window.addEventListener('scroll', function() {
  // current scroll position
  const currentScrollPos = window.pageYOffset;

  if (prevScrollPos > currentScrollPos) {
    // user has scrolled up
    setScroll(false);
  } else {
    // user has scrolled down
    setScroll(true);
  }

  // update previous scroll position
  prevScrollPos = currentScrollPos;
});

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
          document.removeEventListener("click", handleClickOutside, false);
        };
    });


      useEffect(() => {
        return history.listen((location) => { 
          if(location.pathname !== "/" && location.pathname !== "/about"){
            setMobile(false);
         }
         else {
            setMobile(false);
         }
       }) 
       
      }, [history]);


    return (
        <nav className={scroll ? "scrolling" : ""} ref={wrapperRef}>
            <div className='nav-section'>
            <a href="/"><img src={logo} alt=""/></a>
            <ul className="nav-bar" style={{display: mobile ? "flex" : "", transform: mobile ? "translateX(0)" : "", opacity: mobile ? "1" : ""}}>
                <li><a className="menu">Services <i class="fas fa-chevron-down"></i></a>
                    <div className="dropdown-menu">
                      <div className='menu-space'></div>
                        <div className='menu-box'>
                          <NavLink to="/services/engineering-design" className="dropdown" activeClassName="active">Engineering Design</NavLink>
                          <NavLink to="/services/project-management" className="dropdown" activeClassName="active">Project Management</NavLink>
                          <NavLink to="/services/investigation-reports" className="dropdown" activeClassName="active">Investigation & Reports</NavLink>
                        </div>
                    </div>
                </li>
                <li><NavLink to="/projects" activeClassName="active">Projects</NavLink></li>
                <li><NavLink to="/about" activeClassName="active">About</NavLink></li>
                <li><a href="/contact" className="contact-button">Contact Us</a></li>
            </ul>
            <i onClick={() => {setMobile(!mobile); setOverlay(!mobile);}} id="mobile-menu" className="fas fa-bars"></i>
            </div>
        </nav>
    );
}

export default Navbar;