import "./projects.css"
import React, {useEffect, useState} from "react";
import { Blurhash } from "react-blurhash";
import {Helmet} from "react-helmet";

let im1 = "https://res.cloudinary.com/mariah07/image/upload/v1698091308/consult_pinto/grand_w0fw0t_st7dkz.jpg";
let im2 = "https://res.cloudinary.com/mariah07/image/upload/v1698091336/consult_pinto/wedgewood_ceesed_owbbdd.jpg";
let im3 = "https://res.cloudinary.com/mariah07/image/upload/v1698102807/consult_pinto/la-villa_vnzdkm_ljemgf.jpg";
let im4 = "https://res.cloudinary.com/mariah07/image/upload/v1698091300/consult_pinto/convoy_ijzdad_fnr4ec.jpg";
let im5 = "https://res.cloudinary.com/mariah07/image/upload/v1698263815/consult_pinto/waterton2_uh8yod_i665rd.jpg";
let im6 = "https://res.cloudinary.com/mariah07/image/upload/v1698091291/consult_pinto/armoury_iv9npp_xez3zh.jpg";

const Residential = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [im1Loaded, setLoadedIM1] = useState(false);
    const [im2Loaded, setLoadedIM2] = useState(false);
    const [im3Loaded, setLoadedIM3] = useState(false);
    const [im4Loaded, setLoadedIM4] = useState(false);
    const [im5Loaded, setLoadedIM5] = useState(false);
    const [im6Loaded, setLoadedIM6] = useState(false);

    const setLoaded = [setLoadedIM1, setLoadedIM2, setLoadedIM3, setLoadedIM4, setLoadedIM5, setLoadedIM6];
    let i = 0;

    useEffect(() => {
        const imagesPreload = [im1, im2, im3, im4, im5, im6];
        imagesPreload.forEach((image) => {
            const newImage = new Image();
            newImage.onload = () => {
                setLoaded[i](true);
                i = i + 1;
            }
            newImage.src = image;
            window[image] = newImage;
        });
    }, []);
    return (
        <section id="project">
            <Helmet>
                <title>Residential | Pinto Engineering</title>
                <meta name="description" content="PINTO has contributed to nearly 2,500 projects since being established in 1987 including commercial, industrial, residential high-rises, bridges, and more."/>
                <meta name="robots" content="index,follow"/>
            </Helmet>
            <div className="button-area">
                <a className="button" href="/projects"><i class="far fa-arrow-alt-circle-left"></i></a>
                <h1>
                    Residential
                </h1>
            </div>
            <div className="overlay"></div>
            <div className="row">
                <div className="box">
                    <a href="/projects/residential/grand-haven">
                    {!im1Loaded && ( <Blurhash 
                         hash="LYDv{2o~.TRO.9XAoLROkXbJRiad"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im1Loaded && <img loading="eager" src={im1} 
                    alt="Grand Haven Heights - PINTO Engineering"/> }
                    <h3>Grand Haven Heights, Halifax, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/residential/wedgewood-condominium">
                    {!im2Loaded && ( <Blurhash 
                         hash="LhHoF08^tRRQ_4ITs:WCIVt7WAog"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im2Loaded && <img loading="eager" src={im2} 
                    alt="Wedgewood Condominium - PINTO Engineering"/> }
                    <h3>Wedgewood Condominium, Halifax, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/residential/la-villa">
                    {!im3Loaded && ( <Blurhash 
                         hash="LeFsDO%1a#WX?woeaekCWAofaekC"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im3Loaded && <img loading="eager" src={im3} 
                    alt="La Villa Residential Apartment - PINTO Engineering"/> }
                    <h3>La Villa Residential Apartments, Halifax, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/residential/convoy-quay-gardens">
                    {!im4Loaded && ( <Blurhash 
                         hash="LjD]@|MwIU%g?wRORjxutSe-ofRk"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im4Loaded && <img loading="eager" src={im4} 
                    alt="Convoy Quay Gardens - PINTO Engineering"/> }
                    <h3>Convoy Quay Gardens, Halifax, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/residential/the-waterton">
                    {!im5Loaded && ( <Blurhash 
                         hash="LtHCl;xU%gt6tpoHoyof%LofRPf+"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im5Loaded && <img loading="eager" src={im5} 
                    alt="The Waterton - PINTO Engineering"/> }
                    <h3>The Waterton, Halifax, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/residential/armoury-square">
                    {!im6Loaded && ( <Blurhash 
                         hash="LmGSizxtTLbccIRjR.WCJDofrqoM"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im6Loaded && <img loading="eager" src={im6} 
                    alt="Armoury Square - PINTO Engineering"/> }
                    <h3>Armoury Square, Halifax, NS</h3></a>
                </div>
            </div>
        </section>
    )
}

export default Residential;