import React, {useEffect, useRef, useState} from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import './Home.css';
import building from "../img/building.jpg"
import engineer from "../img/engineer.jpg"
import architect from "../img/architect.jpg"
import { Blurhash } from "react-blurhash";
import { Helmet } from "react-helmet";

let image = "https://res.cloudinary.com/mariah07/image/upload/v1698264617/consult_pinto/construction_pinto_p8mpq0.jpg";

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [imLoaded, setLoaded] = useState(false);
    useEffect(() => {
        Aos.init({offset: 300, duration: 500, disable: 'mobile', once: true});

        const newImage = new Image();
        newImage.onload = () => {
                setLoaded(true);
        }
        newImage.src = image;
    }, []);

    const scroll = useRef(null);
    const executeScroll = () => scroll.current.scrollIntoView({ behavior: 'smooth', block: 'start'});
    const scroll2 = useRef(null);
    const scroll3 = useRef(null);
    const executeScroll2 = () => scroll2.current.scrollIntoView({ behavior: 'smooth', block: 'start'});
    const executeScroll3 = () => scroll3.current.scrollIntoView({ behavior: 'smooth', block: 'start'});
    return (
        <section className="home-section">
            <Helmet>
                <title>Pinto Engineering</title>
                <meta name="description" content="PINTO Engineering Limited is an established structural engineering consulting practice having served clients across government, public, and private sectors." />
                <meta name="robots" content="index,follow"/>
            </Helmet>
            <div className="overlay"></div>
            <div className="hero">
                <div className="temp-background">
                {!imLoaded && ( <Blurhash 
                         hash="L*Pi@$_MM|Mxt7Rjoff8bbj?oLWC"
                         width="100%"
                         height={900}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                </div>
                <div className="hero-overlay"></div>
                <div className="hero-text">
                    <h1>Economical <br></br> Solutions.</h1>
                    <h2>We provide 
                    State-of-the-art technology, 
                    highly personalized services, 
                    innovative and cost effective solutions.</h2>
                    <div className="button-area">
                        <a href="/projects" id="project-button">View Our Work</a>
                        <button id="services-button" onClick={executeScroll}>View Services</button>
                    </div>
                    <span><button onClick={executeScroll}>scroll<span className="border-pulse"></span></button>
                    </span>
                </div>
            </div>
            <div className="between">
                <div className="services-items">
                    <div className="anchor" ref={scroll}></div>
                    <div className="services-desc" data-aos="fade-in" data-aos-easing="ease-in-sine" data-aos-duration="500">
                        <h2>Engineering Design</h2>
                        <h3>Buildings, Civil & Marine, Remediation & Restoration</h3>
                        <p>Our design services include reinforced concrete foundations (deep and shallow), reinforced concrete buildings, concrete retaining walls & tunnel structures, structural steel framed buildings, light-gauge steel, tilt-up concrete construction including composite panel design, wood framed construction including panelized projects, heavy timber construction, and temporary shoring.</p>
                        <p>We have a broad range of experience in new construction as well as restoration & remediation of existing structures.</p>
                        <p>PINTO offers our clients comprehensive, on-site investigation services to accompany restoration and remediation projects in order to minimize costly surprises during the construction process.
                        </p>
                        
                    </div>
                    <img src={building} alt=""  data-aos="fade-up-left" data-aos-easing="ease-in-sine"/>
                </div>
                <div className="services-items">
                    <img src={engineer} alt=""  data-aos="fade-up-right" data-aos-easing="ease-in-sine"/>
                    <div className="services-desc" data-aos="fade-in" data-aos-easing="ease-in-sine" data-aos-duration="500">
                        <h2>Investigation & Reports</h2>
                        <h3>Mediation and Arbitration, Feasibility Studies, Condition Assessment</h3>
                        <p>PINTO provides mediation and arbitration services to assist parties in finding mutually agreeable solutions to project disputes.</p>
                        <p>We also offers technical reporting services to determine the feasibility of projects and assist clients with their project decision-making.
                        As part of our technical reporting services, PINTO provides comprehensive condition assessment reports.
                        </p>
                    </div>
                </div>
                <div className="services-items">
                    <div className="services-desc" data-aos="fade-in" data-aos-easing="ease-in-sine" data-aos-duration="500">
                        <h2>Project Management</h2>
                        <h3 id="title3">Construction & Shop Drawing Review, Construction Administration, Project Coordination</h3>
                        <p>We offer a variety of construction administration services such as budget preparation, tender administration & consultation, RFI & change order coordination.
                        </p><p>PINTO works with all stakeholders to effectively resolve issues as construction progresses on site.
                        </p>
                    </div>
                    <img src={architect} alt="" data-aos="fade-up-left" data-aos-easing="ease-in-sine"/>
                </div>
            </div>
            <div className="scroll-section" ref={scroll2}>
                <div className="scrolltop">
                    <h2>Get To Know Our Work</h2>
                    <div className="button-area">
                        <button onClick={executeScroll2}><i class="far fa-arrow-alt-circle-up"></i></button>
                        <button onClick={executeScroll3}><i class="far fa-arrow-alt-circle-down"></i></button>
                    </div>
                </div>
                <div className="scroll-bottom">
                    <div className="animation">
                        <div id="panel1" className="panel panel-text">
                            <div className="text"><h3>ISL Digital Marketing</h3>
                            <p>PINTO Engineering provided structural design and construction administration services for a three-storey office building near the downtown Halifax business district. The limited downtown footprint provided a challenge to coordinate the tilt panels with the interior structural steel since the temporary panel bracing needed to remain in place while the floors were constructed around them. </p>
                            <a href="/projects/commercial/isl-digital-marketing" target="_blank" rel="noopener">Learn More <i class="fas fa-arrow-right"></i></a>
                            </div>
                            <span class="right" id="right-one"></span>
                            <span class="bottom" id="bottom-one"></span>
                        </div>
                        <div id="panel2" className="panel panel-image">
                        </div>
                        <div id="panel3" className="panel panel-text">
                            <div className="text"><h3>Armoury Square</h3>
                            <p>PINTO provided structural design and construction administration services for a 11-storey residential complex overlooking the historic Halifax Common.  The following elements were included in the design: reinforced concrete foundations, reinforced concrete columns and shearwalls, and 236,000 square feet of concrete floor and roof slabs including underground parking. </p>
                            <a href="/projects/residential/armoury-square" target="_blank" rel="noopener">Learn More <i class="fas fa-arrow-right"></i></a>
                            </div>
                            <span class="right" id="right-two"></span>
                            <span class="bottom" id="bottom-two"></span>
                        </div>
                    </div>
                    <div className="animation">
                        <div id="panel4" className="panel panel-image">
                        </div>
                        <div id="panel5" className="panel panel-text">
                            <div className="text"><h3>Bedford Baptist Church</h3>
                            <p>PINTO provided structural design and construction administration services for an extension to the Bedford Baptist Church in Bedford, NS. PINTO received 2004 Tilt-up Achievement Award from the Tilt-up Concrete Association as an outstanding example of tilt-up concrete construction.</p>
                            <a href="/projects/institutional/bedford-church" target="_blank" rel="noopener">Learn More <i class="fas fa-arrow-right"></i></a>
                            </div>
                            <span class="left"></span>
                            <span class="top"></span>
                        </div>
                        <div id="panel6" className="panel panel-image">
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-panel">
            </div>
            <div className="video-section" ref={scroll3}>
                <text className="text"><h3>Our Work on the Big Boat Shed</h3><p>Malcolm PINTO Engineering LTD is proud to have worked on the Big Boat Shed Revitalization Project</p></text>
                
                <div className="video">
                    <iframe width="50%" height="450"
                    src="https://www.youtube.com/embed/FNn2xue0Wb0">
                    </iframe>
                </div>
            </div>
            <div className="end-page">
                <div className="questions">
                    <h2>Have Questions?</h2>
                    <a href="/contact">Send Us a Message</a>
                </div>
            </div>
        </section>
    );
}
export default Home;