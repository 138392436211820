import "./projects.css"
import React, {useEffect, useState} from "react";
import { Blurhash } from "react-blurhash";
import { Helmet } from "react-helmet";

let im1 = "https://res.cloudinary.com/mariah07/image/upload/v1698091336/consult_pinto/wedgewood_ceesed_owbbdd.jpg";
let im2 = "https://res.cloudinary.com/mariah07/image/upload/v1698091315/consult_pinto/isl_tabq15_dequ2l.jpg";
let im3 = "https://res.cloudinary.com/mariah07/image/upload/v1698091327/consult_pinto/SJChurch_fzx52h_cvcwju.jpg";
let im4 = "https://res.cloudinary.com/mariah07/image/upload/v1698091330/consult_pinto/texas_ebd6dq_i7roch.jpg";
let im5 = "https://res.cloudinary.com/mariah07/image/upload/v1698091296/consult_pinto/church_eogdyo_tobx4o.jpg";
let im6 = "https://res.cloudinary.com/mariah07/image/upload/v1698091323/consult_pinto/northwood_oge6kf_o5rjmu.jpg";
let im7 = "https://res.cloudinary.com/mariah07/image/upload/v1698091332/consult_pinto/trillium_jking7_ccu4s6.jpg";
let im8 = "https://res.cloudinary.com/mariah07/image/upload/v1698091320/consult_pinto/mbridge_l1p6jt_hxavk2.jpg";
let im9 = "https://res.cloudinary.com/mariah07/image/upload/v1698091302/consult_pinto/dock_tam7sh_kwyzdm.jpg";

const Projects = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [im1Loaded, setLoadedIM1] = useState(false);
    const [im2Loaded, setLoadedIM2] = useState(false);
    const [im3Loaded, setLoadedIM3] = useState(false);
    const [im4Loaded, setLoadedIM4] = useState(false);
    const [im5Loaded, setLoadedIM5] = useState(false);
    const [im6Loaded, setLoadedIM6] = useState(false);
    const [im7Loaded, setLoadedIM7] = useState(false);
    const [im8Loaded, setLoadedIM8] = useState(false);
    const [im9Loaded, setLoadedIM9] = useState(false);

    const setLoaded = [setLoadedIM1, setLoadedIM2, setLoadedIM3, setLoadedIM4, setLoadedIM5, setLoadedIM6, setLoadedIM7, setLoadedIM8, setLoadedIM9];
    let i = 0;

    useEffect(() => {
        const imagesPreload = [im1, im2, im3, im4, im5, im6, im7, im8, im9];
        imagesPreload.forEach((image) => {
            const newImage = new Image();
            newImage.onload = () => {
                setLoaded[i](true);
                i = i + 1;
            }
            newImage.src = image;
            window[image] = newImage;
        });
    }, []);
    return (
        <section id="project" className="project-page">
            <Helmet>
                <title>Projects | Pinto Engineering</title>
                <meta name="description" content="PINTO has contributed to nearly 2,500 projects since being established in 1987 including commercial, industrial, residential high-rises, bridges, and more."/>
                <meta name="robots" content="index,follow"/>
            </Helmet>
            <div className="button-area">
                <h1>Our Projects</h1>
                <h2>Get to Know our Work</h2>
            </div>
            <div className="overlay"></div>
            <div className="row first-row">
                <div className="box">
                    <a href="/projects/residential">
                        {!im1Loaded && ( <Blurhash 
                         hash="LhHoF08^tRRQ_4ITs:WCIVt7WAog"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im1Loaded && <img loading="eager" src={im1} 
                    alt="Residential projects that Pinto Engineering has worked on"/>}
                    <h3>Residential</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/commercial">
                        {!im2Loaded && ( <Blurhash 
                         hash="LiFGLVt9S8V?.AW?R:WAM{WVayj@"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im2Loaded && <img loading="eager" src={im2}
                    alt="Commercial projects that Pinto Engineering has worked on"/>}
                    <h3>Commerical</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/heritage">
                    {!im3Loaded && ( <Blurhash 
                         hash="LFC@Hq8w9EVrE=aHr:oz8{%hi]bc"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im3Loaded && <img loading="eager" src={im3}
                    alt="Heritage projects that Pinto Engineering has worked on"/>}
                    <h3>Heritage</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/industrial-aviation">
                    {!im4Loaded && ( <Blurhash 
                         hash="LHF5]Z4T?wx80LVrg5sQ~p%1jFoz"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im4Loaded && <img loading="eager" src={im4} alt="Industrial projects that Pinto Engineering has worked on"/> }
                    <h3>Industrial & Aviation</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/institutional">
                    {!im5Loaded && ( <Blurhash 
                         hash="L~Kx6kR.%Mj[_4WBkCj[x[WBM{jZ"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im5Loaded && <img loading="eager" src={im5} 
                    alt="Institutional projects that Pinto Engineering has worked on"/>}
                    <h3>Institutional</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/health">
                    {!im6Loaded && ( <Blurhash 
                         hash="LGD+#$-OI=WB1BoIs-WY01I[wbj]"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im6Loaded && <img loading="eager" src={im6} 
                    alt="Hospital & Wellness projects that Pinto Engineering has worked on"/>}
                    <h3>Health</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/mixed-use-development">
                    {!im7Loaded && ( <Blurhash 
                         hash="LXF6qqp0KBg4N#RjXAn#ROaen}s+"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im7Loaded && <img loading="eager" src={im7} 
                    alt="Mixed Use Development projects that Pinto Engineering has worked on"/>}
                    <h3>Mixed-Use Development</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/bridges">
                    {!im8Loaded && ( <Blurhash 
                         hash="LuDw,cWsV[kC%%WXf6fkIWayofay"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im8Loaded && <img loading="eager" src={im8}
                    alt="Bridges that Pinto Engineering has worked on"/> }
                    <h3>Bridges</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/wharves">
                    {!im9Loaded && ( <Blurhash 
                         hash="L+IrW@xuxuof_Njsofj[%MV@Riay"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im9Loaded && <img loading="eager" src={im9} 
                    alt="Wharves projects that Pinto Engineering has worked on"/> }
                    <h3>Wharves</h3></a>
                </div>
            </div>
        </section>
    )
}

export default Projects;