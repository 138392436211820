import "./projects.css"
import React, {useEffect, useState} from "react";
import { Blurhash } from "react-blurhash";
import {Helmet} from "react-helmet";

let im1 = "https://res.cloudinary.com/mariah07/image/upload/v1698091297/consult_pinto/citadel_azevql_amu6fg.jpg";
let im2 = "https://res.cloudinary.com/mariah07/image/upload/v1698091311/consult_pinto/Highfield_uxjnsk_bu36xy.jpg"; 
let im3 = "https://res.cloudinary.com/mariah07/image/upload/v1698091316/consult_pinto/market_cac4z1_q6pxou.jpg";
let im4 = "https://res.cloudinary.com/mariah07/image/upload/v1698091315/consult_pinto/isl_tabq15_dequ2l.jpg";
let im5 = "https://res.cloudinary.com/mariah07/image/upload/v1698263726/consult_pinto/glen_masdnz_ncekp6.jpg";
let im6 = "https://res.cloudinary.com/mariah07/image/upload/v1698091301/consult_pinto/coving_qnkagl_kevrox.jpg";
let im7 = "https://res.cloudinary.com/mariah07/image/upload/v1698091298/consult_pinto/comfort_tjffdo_kciobl.jpg";

const Commerical = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [im1Loaded, setLoadedIM1] = useState(false);
    const [im2Loaded, setLoadedIM2] = useState(false);
    const [im3Loaded, setLoadedIM3] = useState(false);
    const [im4Loaded, setLoadedIM4] = useState(false);
    const [im5Loaded, setLoadedIM5] = useState(false);
    const [im6Loaded, setLoadedIM6] = useState(false);
    const [im7Loaded, setLoadedIM7] = useState(false);

    const setLoaded = [setLoadedIM1, setLoadedIM2, setLoadedIM3, setLoadedIM4, setLoadedIM5, setLoadedIM6, setLoadedIM7];
    let i = 0;

    useEffect(() => {
        const imagesPreload = [im1, im2, im3, im4, im5, im6, im7];
        imagesPreload.forEach((image) => {
            const newImage = new Image();
            newImage.onload = () => {
                setLoaded[i](true);
                i = i + 1;
            }
            newImage.src = image;
            window[image] = newImage;
        });
    }, []);
    return (
        <section id="project">
            <Helmet>
                <title>Commercial | Pinto Engineering</title>
                <meta name="description" content="PINTO has contributed to nearly 2,500 projects since being established in 1987 including commercial, industrial, residential high-rises, bridges, and more."/>
                <meta name="robots" content="index,follow"/>
            </Helmet>
            <div className="button-area">
                <a className="button" href="/projects"><i class="far fa-arrow-alt-circle-left"></i></a>
                <h1>Commerical</h1>
            </div>
            <div className="overlay"></div>
            <div className="row">
                <div className="box">
                    <a href="/projects/commercial/citadel-inn">
                    {!im1Loaded && ( <Blurhash 
                         hash="LTEN6o.8KSMxO_o#o#ngjERjR3jF"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im1Loaded && <img loading="eager" src={im1} 
                    alt="Citadel Inn - PINTO Engineering"/> }
                    <h3>Citadel Inn Redevelopment, Halifax, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/commercial/highfield-plaza">
                    {!im2Loaded && ( <Blurhash 
                         hash="LdHCf%IW?bIT.AXA%MnhxYozROs."
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im2Loaded && <img loading="eager" src={im2}
                    alt="Highfield Plaza - PINTO Engineering"/> }
                    <h3>Highfield Plaza, Dartmouth, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/commercial/market-square">
                    {!im3Loaded && ( <Blurhash 
                         hash="LzIOwwM{IUWB_4RjRjj@xvayj[j@"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im3Loaded && <img loading="eager" src={im3} 
                    alt="Market Square - PINTO Engineering"/> }
                    <h3>Market Square, Saint John, NB</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/commercial/isl-digital-marketing">
                    {!im4Loaded && ( <Blurhash 
                         hash="LiFGLVt9S8V?.AW?R:WAM{WVayj@"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im4Loaded && <img loading="eager" src={im4} 
                    alt="ISL Digital Marketing - PINTO Engineering"/> }
                    <h3>ISL Digital Marketing, Halifax, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/commercial/glenbourne-plaza">
                    {!im5Loaded && ( <Blurhash 
                         hash="LwEzE6MxWXWBX=e-a#ayRoo#oJof"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im5Loaded && <img loading="eager" src={im5} 
                    alt="Glenbourne Plaza - PINTO Engineering"/> }
                    <h3>Glenbourne Plaza, Halifax, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/commercial/covington-station">
                    {!im6Loaded && ( <Blurhash 
                         hash="LLE{U%RmTMi^lCNHohn$59WCZ~Wo"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im6Loaded && <img loading="eager" src={im6} 
                    alt="Covington Station - PINTO Engineering"/> }
                    <h3>Covington Station, Kentville, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/commercial/comfort-inn">
                    {!im7Loaded && ( <Blurhash 
                         hash="LyI#iVV[xuWB~qWBozWB-=WBWBj["
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im7Loaded && <img loading="eager" src={im7} 
                    alt="Comfort Inn - PINTO Engineering"/> }
                    <h3>Comfort Inn, Halifax, NS</h3></a>
                </div>
            </div>
        </section>
    )
}

export default Commerical;