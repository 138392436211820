import "./projects.css"
import React, {useEffect, useState} from "react";
import { Blurhash } from "react-blurhash";
import {Helmet} from "react-helmet";

let im1 = "https://res.cloudinary.com/mariah07/image/upload/v1698091340/consult_pinto/woodside_xsyc1r_jvfrlv.jpg";
let im2 = "https://res.cloudinary.com/mariah07/image/upload/v1698091302/consult_pinto/dock_tam7sh_kwyzdm.jpg";

const Wharves = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [im1Loaded, setLoadedIM1] = useState(false);
    const [im2Loaded, setLoadedIM2] = useState(false);

    const setLoaded = [setLoadedIM1, setLoadedIM2];
    let i = 0;

    useEffect(() => {
        const imagesPreload = [im1, im2];
        imagesPreload.forEach((image) => {
            const newImage = new Image();
            newImage.onload = () => {
                setLoaded[i](true);
                i = i + 1;
            }
            newImage.src = image;
            window[image] = newImage;
        });
    }, []);
    return (
        <section id="project">
            <Helmet>
                <title>Wharves | Pinto Engineering</title>
                <meta name="description" content="PINTO has contributed to nearly 2,500 projects since being established in 1987 including commercial, industrial, residential high-rises, bridges, and more."/>
                <meta name="robots" content="index,follow"/>
            </Helmet>
            <div className="button-area">
                <a className="button" href="/projects"><i class="far fa-arrow-alt-circle-left"></i></a>
                <h1>Wharves</h1>
            </div>
            <div className="overlay"></div>
            <div className="row">
                <div className="box">
                    <a href="/projects/wharves/woodside-ferry">
                    {!im1Loaded && ( <Blurhash 
                         hash="LwG]RC%Mxtt7?wn#aefk%ht6WAay"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im1Loaded && <img loading="eager" src={im1} 
                    alt="Woodside Ferry Terminal Pontoon - PINTO Engineering"/> }
                    <h3>Woodside Ferry Terminal Pontoon, Dartmouth, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/wharves/national-gypsum-dock">
                    {!im2Loaded && ( <Blurhash 
                         hash="L+IrW@xuxuof_Njsofj[%MV@Riay"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im2Loaded && <img loading="eager" src={im2} 
                    alt="National Gypsum Dock - PINTO Engineering"/> }
                    <h3>National Gypsum Dock, Dartmouth, NS</h3></a>
                </div>
            </div>
        </section>
    )
}

export default Wharves;