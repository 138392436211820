import "./projects.css"
import React, {useEffect, useState} from "react";
import { Blurhash } from "react-blurhash";
import {Helmet} from "react-helmet";

let im1 = "https://res.cloudinary.com/mariah07/image/upload/v1698091325/consult_pinto/perkins_vbkf5s_kesdvk.jpg";
let im2 = "https://res.cloudinary.com/mariah07/image/upload/v1698091327/consult_pinto/SJChurch_fzx52h_cvcwju.jpg"; 
let im3 = "https://res.cloudinary.com/mariah07/image/upload/v1698091322/consult_pinto/morris_gjpzoq_q0a7u4.jpg";
let im4 = "https://res.cloudinary.com/mariah07/image/upload/v1698263737/consult_pinto/george3_vil4ar_vdp7mn.jpg";
let im5 = "https://res.cloudinary.com/mariah07/image/upload/v1698091333/consult_pinto/uniacke_zegxym_l3itg7.jpg";

const Heritage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [im1Loaded, setLoadedIM1] = useState(false);
    const [im2Loaded, setLoadedIM2] = useState(false);
    const [im3Loaded, setLoadedIM3] = useState(false);
    const [im4Loaded, setLoadedIM4] = useState(false);
    const [im5Loaded, setLoadedIM5] = useState(false);

    const setLoaded = [setLoadedIM1, setLoadedIM2, setLoadedIM3, setLoadedIM4, setLoadedIM5];
    let i = 0;

    useEffect(() => {
        const imagesPreload = [im1, im2, im3, im4, im5];
        imagesPreload.forEach((image) => {
            const newImage = new Image();
            newImage.onload = () => {
                setLoaded[i](true);
                i = i + 1;
            }
            newImage.src = image;
            window[image] = newImage;
        });
    }, []);
    return (
        <section id="project">
            <Helmet>
                <title>Heritage | Pinto Engineering</title>
                <meta name="description" content="PINTO has contributed to nearly 2,500 projects since being established in 1987 including commercial, industrial, residential high-rises, bridges, and more."/>
                <meta name="robots" content="index,follow"/>
            </Helmet>
            <div className="button-area">
                <a className="button" href="/projects"><i class="far fa-arrow-alt-circle-left"></i></a>
                <h1>Heritage</h1>
            </div>
            <div className="overlay"></div>
            <div className="row">
                <div className="box">
                    <a href="/projects/heritage/perkins-house-museum">
                    {!im1Loaded && ( <Blurhash 
                         hash="LuGlO_M{jbt7~XRjoft7?bRjWCj["
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im1Loaded && <img loading="eager" src={im1} 
                    alt="Perkins House Museum - PINTO Engineering"/> }
                    <h3>Perkins House Museum, Liverpool, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/heritage/st-john-anglican-church">
                    {!im2Loaded && ( <Blurhash 
                         hash="LFC@Hq8w9EVrE=aHr:oz8{%hi]bc"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im2Loaded && <img loading="eager" src={im2}
                    alt="St John's Anglican Church Restoration - PINTO Engineering"/> }
                    <h3>St John’s Anglican Church Restoration, Lunenburg, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/heritage/morris-street">
                    {!im3Loaded && ( <Blurhash 
                         hash="LNF~N+~q%MxutRofozxuaKofs;bH"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im3Loaded && <img loading="eager" src={im3}
                    alt="Morris Street Renovation - PINTO Engineering"/> }
                    <h3>5247 Morris Street Renovation, Halifax, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/heritage/st-george-anglican-church">
                    {!im4Loaded && ( <Blurhash 
                         hash="LmIOtqIu.7R,~qIpt7Rkj]WCM{oK"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im4Loaded && <img loading="eager" src={im4}
                    alt="St George Anglican Church - PINTO Engineering"/> }
                    <h3>St. Georges Anglican Church Restoration, Halifax, NS</h3></a>
                </div>
                <div className="box">
                    <a href="/projects/heritage/uniacke-estate-museum">
                    {!im5Loaded && ( <Blurhash 
                         hash="L9F~w5E:00NH5w?dIwM{00Mw^+Mx"
                         width="100%"
                         height={300}
                         resolutionX={32}
                         resolutionY={32}
                         punch={1}/>
                        )}
                        {im5Loaded && <img loading="eager" src={im5} 
                    alt="Uniacke Estate Museum - PINTO Engineering"/> }
                    <h3>Uniacke Estate Museum, Mt. Uniacke, NS</h3></a>
                </div>
            </div>
        </section>
    )
}

export default Heritage;